var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "steps-progress-bar" },
        _vm._l(_vm.totalColumns, function (index) {
          return _c(
            "div",
            { key: index, class: _vm.getColumnClass(index) },
            [
              _vm.isCircleColumn(index)
                ? _c(
                    "div",
                    { class: ["circle", _vm.getCircleState(index)] },
                    [
                      _vm.lastIcon(index)
                        ? [
                            _c("font-awesome-svg", {
                              attrs: {
                                type: "flag-checkered",
                                fill: "white",
                                width: _vm.iconSize,
                                height: _vm.iconSize,
                              },
                            }),
                          ]
                        : _vm.completeIcon(index)
                        ? [
                            _c("font-awesome-svg", {
                              attrs: {
                                type: "check-solid",
                                fill: _vm.defaultFill,
                                width: _vm.iconSize,
                                height: _vm.iconSize,
                              },
                            }),
                          ]
                        : _vm.activeIcon(index)
                        ? [
                            _c("font-awesome-svg", {
                              attrs: {
                                type: "pencil",
                                fill: _vm.defaultFill,
                                width: _vm.iconSize,
                                height: _vm.iconSize,
                              },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : [_c("div", { class: ["line", _vm.getLineState(index)] })],
            ],
            2
          )
        }),
        0
      )
    : _c(
        "div",
        { staticClass: "center-spinner" },
        [_c("ct-centered-spinner")],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }