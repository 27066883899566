<template>
  <div
    v-if="loaded"
    class="steps-progress-bar"
  >
    <div
      v-for="index in totalColumns"
      :key="index"
      :class="getColumnClass(index)"
    >
      <div
        v-if="isCircleColumn(index)"
        :class="['circle', getCircleState(index)]"
      >
        <template v-if="lastIcon(index)">
          <font-awesome-svg
            :type="'flag-checkered'"
            :fill="'white'"
            :width="iconSize"
            :height="iconSize"
          />
        </template>
        <template v-else-if="completeIcon(index)">
          <font-awesome-svg
            :type="'check-solid'"
            :fill="defaultFill"
            :width="iconSize"
            :height="iconSize"
          />
        </template>
        <template v-else-if="activeIcon(index)">
          <font-awesome-svg
            :type="'pencil'"
            :fill="defaultFill"
            :width="iconSize"
            :height="iconSize"
          />
        </template>
      </div>

      <template v-else>
        <div :class="['line', getLineState(index)]" />
      </template>
    </div>
  </div>
  <div
    v-else
    class="center-spinner"
  >
    <ct-centered-spinner />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FontAwesomeSvg from '@/components/shared/FontAwesomeSvg'

export default {
  name: 'ProgressBar',
  components: {
    FontAwesomeSvg,
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
  },
  props: {
    progressStatus: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      loaded: false,
      iconSize: 10,
      defaultFill: '#2FB2AE',
      slides: [
        'SelectJurisdiction',
        'SelectEntityType',
        'CompanyName',
        'SimilarCompanyName',
        'ConfirmCompany',
        'CompanyCreated',
      ],
    }
  },
  computed: {
    ...mapGetters('stagelineCreateCompany', [
      'currentSlide',
    ]),
    totalColumns() {
      return this.slides.length * 2 - 1
    },
  },
  async mounted () {
    this.loaded = true
  },
  methods: {
    isCircleColumn(index) {
      return index % 2 !== 0
    },
    getColumnClass(index) {
      return index % 2 !== 0 ? 'fixed-column' : 'responsive-column'
    },
    getSlideIndex(index) {
      return Math.floor(index / 2)
    },
    activeIcon(index) {
      return this.getSlideIndex(index) === this.slides.indexOf(this.currentSlide)
    },
    completeIcon(index) {
      return this.getSlideIndex(index) < this.slides.indexOf(this.currentSlide)
    },
    lastIcon(index) {
      return index === (this.slides.length - 1) * 2 + 1
    },
    getCircleState(index) {
      const conditions = {
        'last-active': this.lastIcon(index) && this.activeIcon(index),
        'completed': this.completeIcon(index),
        'active': this.activeIcon(index),
        'last': this.lastIcon(index),
        'inactive': true,
      }

      return Object.keys(conditions).find(key => conditions[key])
    },
    getLineState(index) {
      const slideIndex = this.getSlideIndex(index - 1)

      if (slideIndex < this.slides.indexOf(this.currentSlide)) return 'completed'
      if (slideIndex === this.slides.indexOf(this.currentSlide)) return 'active'
      return 'inactive'
    },
  },
}
</script>

<style lang="scss" scoped>
.steps-progress-bar {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  background: white;
  @include sl__flex(row, center, center);

  .fixed-column {
    width: 1.625em;
    @include sl__flex(row, center, center);

    .circle {
      width: 1.625em;
      height: 1.625em;
      border-radius: 50%;
      @include sl__flex(row, center, center);

      &.completed,
      &.active {
        border: 0.25em solid $sl__teal1;
        background: white;
      }

      &.inactive {
        width: 1.0em;
        height: 1.0em;
        border: 0.125em solid $sl__grey2-shade3;
        background: white;
      }

      &.last {
        border: 0.25em solid $sl__grey2-shade3;
        background: $sl__grey2-shade3;
      }

      &.last-active {
        border: 0.25em solid $sl__teal1;
        background: $sl__teal1;
      }
    }
  }

  .responsive-column {
    flex-grow: 1;
    padding: 0 0.25em;

    .line {
      width: 100%;
      height: 0.5em;
      border-radius: 0.5em;

      &.completed {
        background: $sl__teal1;
      }

      &.active {
        background: $sl__teal1-shade1;
      }

      &.inactive {
        background: $sl__grey2-shade3;
      }
    }
  }
}
</style>
